main .loaders {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
}

main .loaders .loader {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 25%;
    max-width: 25%;
    height: 200px;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes line-spin-fade-loader {
    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

@keyframes line-spin-fade-loader {
    50% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}

.line-spin-fade-loader {
    position: relative;
    top: -10px;
    left: -4px;
}

.line-spin-fade-loader>div:nth-child(1) {
    top: 5px;
    left: 0;
    -webkit-animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(2) {
    top: 3.63636px;
    left: 3.63636px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(3) {
    top: 0px;
    left: 5px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(4) {
    top: -3.63636px;
    left: 3.63636px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(5) {
    top: -5px;
    left: 0px;
    -webkit-animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(6) {
    top: -3.63636px;
    left: -3.63636px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(7) {
    top: 0px;
    left: -5px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
}

.line-spin-fade-loader>div:nth-child(8) {
    top: 3.63636px;
    left: -3.63636px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
    animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
}

.line-spin-fade-loader>div {
    background-color: #fff;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 1px;
    height: 5px;
}