

/* Css additions for timelne Start */
.vis-timeline {
    border: none !important;
  }
  
  .vis-item.screen {
    background-color: #24262f !important;
    border-top: 40px solid #0f1217 !important;
    border-bottom: 10px solid #0f1217 !important;
    border-radius: 0;
    color: rgba(255, 255, 255, 0.6)
  }
  
  .vis-time-axis .vis-grid.vis-minor,
  .vis-time-axis .vis-grid.vis-major {
    border-color: rgba(255, 255, 255, 0.06) !important;
    /* border: none !important; */
  }
  
  .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
    border: none !important;
  }
  
  .vis-axis .vis-group {
    display: none;
  }
  
  .vis-text{
    color: #6E7681 !important;
  }
  
  .vis-group:nth-child(1) .vis-item.screen {
    border-top-width: 0 !important;
  }
  
  .vis-item.screen .vis-item-content {
    padding-left: 12px;
  }
  
  .vis-group {
    background-color: rgba(255, 255, 255, 0.01);
    border-bottom: solid 1px #222730 !important;
    /* min-height: 84px; */
  }
  
  .vis-item.schedule {
    border-radius: 6px !important;
    border: none !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 48px;
  }
  
  .vis-item.schedule::before,.vis-item.schedule::after {
    display: block;
    width: 2px;
    height: 20px;
    position: absolute;
    transform: translateY(50%);
    bottom: 50%;
    color: #131601;
    content: '\2807';
    font-size: 20px;
    /* border-radius: 2px; */
    /* background: #110106; */
    /* content: ""; */
  }
  
  /* 
  .vis-item.schedule::before,.vis-item.schedule::after {
    display: block;
    content: "";
    width: 2px;
    height: 20px;
    background: black;
    border-radius: 2px;
    position: absolute;
    transform: translateY(50%);
    bottom: 50%;
    opacity: 0.3;
  } */
  
  .vis-item.schedule::before {
    left: 12px;
  }
  
  .vis-item.schedule::after {
    right: 20px;
  }
  
  .vis-item.schedule small {
    display: block;
    font-size: 12px;
  }

  .vis-item.schedule img {
    height: 32px;
    width: 32px;  
    border-radius: 4px; 
    margin-right: 8px;
  }
  
  .vis-item.schedule div {
    display: flex; 
    flex-direction: row; 
    justify-content: flex-start; 
    align-items: center; 
    height: 100%;
  }
  
  .vis-item-content div > div {
    display: flex ; 
    flex-direction: column !important; 
    justify-content: flex-start !important; 
    align-items: flex-start !important; 
  }
  
  .vis-item.schedule.blue {
    background-color: #0E78B5;
  }
  
  .vis-item.schedule.green {
    background-color: #73DF5C;
  } 
  
  .vis-item.schedule.lime {
    background-color: #DEF169;
  } 
  
  .vis-item.schedule.orange {
    background-color: #EBB369;
  }
  
  .vis-item.schedule.red {
    background-color: #E94079;
  }
  
  .vis-item.schedule.teal {
    background-color: #55A9D2;
  }
  
  .vis-item.schedule.white {
    background-color: #F2F4F7;
  }
  
div.vis-tooltip {
  padding: 12px;
  max-width: 300px;
}
div.vis-tooltip p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


  /* Css additions for timelne End */
  
  