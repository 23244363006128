@tailwind base;
@tailwind components;
@tailwind utilities;

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
  box-shadow: none;
  border: none;
}

[type='checkbox']:focus, [type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgba(255, 255, 255, 0);
  --tw-ring-color: #2564eb00;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

input {
  border: none;
}

@font-face {
  font-family: "Avenir";
  src: url("../public/fonts/AvenirLTStd-Book.otf");
}
.asset_preview:hover .asset {
  display: block;
}

.asset_preview .asset {
  display: none;
}

